.custom-form-width {
  min-width: 300px !important;
}
.form-width {
  width: 700px !important;
}
.rct-form-title {
  padding: 0 0 15px 0;
  p {
    font-size: 24px;
    font-weight: 500;
  }
}
.form-group {
  margin-bottom: 20px !important;
}
.submit-position {
  float: right;
}
.submit-padding {
  padding: 20px 0;
}
.multi-forms {
  display: flex !important;
  flex-wrap: wrap !important;
  > div {
    flex: 1 40% !important;
    //flex: 1 50% !important;
    padding-right: 20px !important;
  }
}
.multi-forms2 {
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.img-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-button {
  background-color: #f7d1d1 !important;
  max-width: 30% !important;
  span {
    color: #eb5757;
  }
}
.img-button-disable {
  background-color: #e6e7ef !important;
  max-width: 30% !important;
  span {
    color: #bcc0d1 !important;
  }
}
.stats {
  width: 81%;
}
.custom-chats-width {
  width: 900px !important;
}
.invitation-width {
  width: 1000px !important;
}
