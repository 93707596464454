body.rtl {
  .table-padding {
    td {
      text-align: right !important;
    }
  }
  .table-dragDrop thead th {
    text-align: right !important;
    margin-right: 15px !important;
  }
  .table-pages-pagination {
    ul {
      margin-left: 10px !important;
    }
  }
  .default-width {
    text-align: right !important;
  }
  .toolbarIcon {
    float: left !important;
  }
  .customRow {
    text-align: right !important;
    span {
      margin-right: 0 !important;
    }
  }
  .customRowOneIcon {
    text-align: right !important;
    span {
      margin-left: 30px !important;
      margin-right: -10px !important;
    }
    svg {
      margin-left: -40px !important;
    }
  }
}
//end RTL
//Toolbar
.toolbarIcon {
  float: right;
  background: $cardBorderColor !important;
  border-radius: 10px !important;
}

.toolbarIcon:hover {
  background: #f8f8fc !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 12px 16px rgba(0, 0, 0, 0.08) !important;
}
.table-padding {
  th {
    padding: 6px 16px 6px 16px;
  }
  td {
    padding: 6px 16px 6px 16px;
  }
}
tbody {
  td {
    color: rgba(66, 68, 77, 0.65);
  }
  tr:nth-child(even) {
    background-color: #ffffff;
  }
  tr:nth-child(odd) {
    background-color: #fafaf9;
  }
}
.customRowOneIcon {
  text-align: left;
  position: relative !important;
  span {
    margin-right: 20px;
  }
  svg {
    color: #bcc0d1;
    cursor: pointer !important;
    justify-content: center;
    top: 3px;
    margin-right: -15px;
  }
}
.rectangular-image-table {
  height: 90px;
  width: 150px;
  margin: 10px;
}
.rectangular-image-table:hover {
  cursor: pointer;
}
.square-image-table:hover {
  cursor: pointer;
}
.square-image-table {
  height: 80px;
  width: 80px;
  border-radius: 12px;
}
.table-pagination {
  min-height: 70px;
  //min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //padding: 20px;
}
.table-pages-pagination {
  ul {
    margin-right: 10px;
  }
}
.table-rows-pagination {
  color: rgba(0, 0, 0, 0.54) !important;
}
.default-width {
  width: auto !important;
  font-size: 18px;
  text-align: left;
  line-height: 1.5rem;
}
//drag drop table
.dragDrop-table-header {
  text-align: left;
  min-width: 150px;
  color: #9699a9;
  padding: 15px 0 15px 0;
  font-size: 1rem;
  letter-spacing: -0.5px;
}
.default-width-drag-drop {
  width: auto !important;
}
.on-drag-item {
  box-shadow: 0 8px 36px rgba(0, 0, 0, 0.16) !important;
  background-color: #ffffff !important;
}
.custom-table-cursor {
  th {
    font-weight: normal !important;
  }
  td:hover {
    cursor: grab !important;
  }
}
.rtc-no-shadow {
  .rct-block {
    box-shadow: none !important;
  }
}
.rtc-no-padding {
  .rct-block-content {
    padding: 0 !important;
  }
}

table th {
  white-space: nowrap;
  font-weight: normal !important;
}
.image-table {
  height: 90px;
  width: auto;
  margin: 10px;
}

td {
  .hover-cell {
    padding-right: 18px;
  }
}

tr {
  cursor: default !important;
}
.table th,
.table td {
  vertical-align: middle !important;
  word-break: break-word;
}
.sticky-table-header {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
}
.header-shadow {
  box-shadow: 3px 2px 2px 0 #e6e7ef;
}
.drag-drop-wrapper {
  width: 100%;
  max-height: 630px;
  overflow-y: auto;
}
.actions-style {
  min-width: 100px !important;
  max-width: 150px !important;
}
