.orders-filters {
  margin-top: 14px;
}

.orders-tab-bar {
  background-color: #0075c9;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;

  header {
    box-shadow: none;
  }
}

.filter-style {
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 15px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.modal-dialog {
  max-width: 60% !important;
}

.customerModal {
  @media only screen and (min-device-width: 768px) {
    max-width: 20% !important;
  }
  @media only screen and (max-device-width: 768px) {
    max-width: 100% !important;
  }
}

.order-details {
  width: 100%;
  max-width: 100% !important;
}

.order-details-container {
  .row {
    padding: 15px 0;
  }

  .order-details-header {
    font-weight: bold;
  }

  .order-details-items {
    font-size: 14px;
  }
}

// .layout-opacity {
//background: rgba(0, 0, 0, 0.5) !important;
// box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
// border: 0.8px solid rgba(0, 0, 0, 0.05);
// }

.bg-custom {
  background-color: $primary;
}

#filter.slideIn {
  opacity: 1;
  height: 100%;
  display: flex;
}

#filter.slideOut {
  opacity: 0;
  height: 0;
  display: none;
}

#filter {
  opacity: 1;
  height: 100%;
  display: none;
  transition: opacity 250ms ease-in, height 250ms ease-in;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.filter-grid {
  display: flex;
  flex-direction: column;
}

.mt-15 {
  margin-top: 15px;
}

//.inner-table {
//  tr th {
//    background: #FFF !important;
//    color: #0075c9;
//  }
//
//  border: none !important;
//}

//.inner-table td {
//  border: none !important;
//}

.inner-table.border {
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}

.border .p {
  padding: 0 !important;
}

.auto-height {
  height: auto !important;
}

.rtc-no-shadow {
  .rct-block {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}

.rtc-no-padding {
  .rct-block-content {
    padding: 0 !important;
  }
}

.rtc-no-margin {
  .rct-block {
    margin: 0 !important;
  }
}

.rtc-tabs {
  .rct-block-content {
    border-bottom: 1px solid #e8e8e8;
  }
}

.border-head-table {
  border: 1px solid #ccc;
}

.sortIcon {
  display: flex;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 4px;
    //margin-right: 4px;
    align-self: center;
  }
}

.fee-inputs {
  padding-right: 24px;
  padding-left: 24px;
}

.list-fees-container {
  align-items: flex-start;
}

.list-fees-elements {
  align-items: flex-start;
}

.floating-actions {
  left: 15px;
  right: 0 !important;
}

.btn *,
a[role='button'],
button[type='button'] {
  text-transform: initial;
}

.table td.order-table-cell {
  padding-left: 15px !important;
}

body.rtl {
  .invitation-padding {
    padding-left: 15px !important;
  }
  .support-noInternet-loader {
    margin-left: 5px !important;
  }
  .rce-mbox-time {
    float: left !important;
    text-align: left !important;
    direction: ltr !important;
    position: unset !important;
  }
  .align-right {
    text-align: right !important;
    direction: rtl !important;
  }
  .closeButton {
    position: absolute !important;
    left: 10px;
    top: 11px;
    right: inherit;
  }

  .time-slot-padding {
    padding-left: 15px;
    padding-right: initial;
  }

  .delete-icon-dialog {
    position: absolute !important;
    left: 50px;
    top: 11px;
    right: inherit;
  }

  [class*='headTable'] {
    text-align: right !important;
  }

  [class*='float-cells'] {
    text-align: right !important;
  }

  .text-align {
    text-align: right !important;
  }

  .expand-icon {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
  }

  //.single-filter-icon {
  //  position: absolute;
  //  left: 25px;
  //  right: initial;
  //  // margin-left: 6px;
  //}
  //
  //.sort-icon {
  //  left: 0;
  //  right: initial;
  //}

  .clearFilter {
    float: left;
  }

  .dialog-order-header {
    text-align: right;
  }

  .rct-block-title h4 {
    font-weight: bold;
  }

  .complete-order-price span {
    text-align: left !important;
  }

  .export-excel {
    float: left !important;
  }

  .report-clear {
    margin-right: 15px !important;
  }

  .ql-toolbar .ql-snow {
    direction: rtl;
    text-align: right;
  }

  .ql-editor {
    margin-left: 16px;
    margin-right: 16px;
    font-family: 'Helvetica Neue';
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    margin-right: 80px !important;
  }
  //.customRowOneIcon {
  //  display: flex;
  //  flex-direction: row;
  //  flex-wrap: nowrap;
  //  align-items: center;
  //  justify-content: center;
  //  position: relative;
  //  padding-right: 60px;
  //  padding-left: 60px;
  //
  //  svg {
  //    fill: #8c8c8c;
  //    cursor: pointer !important;
  //  }
  //}
  .breadcrumbCheck {
    margin-top: 11px !important;
    margin-right: 8px !important ;
  }
  .notification-success:before {
    right: 5.6% !important;
  }

  .notification-error:before {
    right: 5.6% !important;
  }
  .notification .title {
    margin-right: 50px !important;
  }
  .notification .message {
    margin-right: 50px !important;
  }
}

.ordersTabs {
  /*[class*="MuiTab-root"] {
    min-width: 125px;
  }
  [class*="MuiListItem"] {
    padding-left: 0;
    padding-right: 0;
  }*/
  .order-info-icon {
    margin-left: 3%;
  }

  /* .order-info-title {
     //font-size: 12px;
     color: #A5A5A5;
   }*/

  .order-info {
    font-size: 14px;
  }

  .order-info-date {
    font-size: 12px;
    color: #c9c9c9;
  }

  .active-order {
    background-color: #f7f7f7;
  }

  .agent-name {
    display: block;
  }

  .timing {
    font-size: 14px;
    color: #000000;
  }

  .container-div {
    position: relative;
  }

  .order-list-row {
    padding-left: 22px;
  }
}

.selected-order-drawer {
  li {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 6px;
  }

  .drawer-header-title {
    font-weight: bold;
  }

  span.line-break {
    display: block;
  }

  .drawer-button {
    min-width: 80%;
  }
}

.note {
  color: rgba(0, 0, 0, 1) !important;
}

.fee-inputs-buttons {
  padding: 24px;
}

.padding-18 {
  padding-top: 18px;
  padding-right: 18px;
  padding-left: 18px;
}

.rtc-18 {
  padding-right: 18px;
  padding-left: 18px;
}

.width-action-orders {
  min-width: 50px !important;

  span {
    padding: 5px !important;
  }
}

.modal-table {
  tr th {
    border: 0;
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: normal !important;
    background-color: #fff;
  }

  td {
    border: 0;
  }

  .headTable {
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: normal;
    text-align: left;
  }

  .with-top-border {
    border-top: 1px solid #f1f1f1;
  }

  .float-cells {
    text-align: left;
  }
}

button.close,
button.close:hover,
button.close:focus {
  text-shadow: none;
  color: #fff;
  background-color: unset;
  opacity: 1;
}

.list-without-padding {
  align-items: flex-start;
  height: fit-content;
  padding-top: 0 !important;

  li {
    padding-top: 0;
  }
}

.deliveryModal {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.orderHistoryModal {
  max-width: 20% !important;
}

.title-map-delivery {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5em;
}

.modal-content {
  border: none !important;
}

.orderModal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100%;

  @media only screen and (max-device-width: 640px) and (max-device-width: 768px) {
    max-width: 100% !important;
    height: 100% !important;
    margin: 0 !important;

    table {
      display: block !important;
    }
  }
}

@media (max-width: 472px) {
  .page-title h2 {
    width: fit-content;
  }
}

.text-align {
  text-align: left !important;
}

.expand-icon {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
}

.map-layout {
  align-items: flex-start;
  height: fit-content;
}

//.table-responsive .rct-block {
//display: inline-flex;
//}
.edit-order-dialog {
  .row {
    padding: 20px 0 0;
  }
}

.time-slot-layout {
  margin: 0 !important;
  justify-content: space-between;
  align-items: center;
}

.time-slot-chips {
  background-color: #1e3b6b !important;
  color: #fff !important;
}

.label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.time-slot-chips-layout {
  background: #fff !important;
  border: 2px solid #1e3b6b !important;
  color: #1e3b6b !important;
}

.selected-time-slot {
  background-color: #00d1bd !important;
  color: #fff !important;
  border: 2px solid #02bba9 !important;
}

.container-for-half {
  flex-wrap: wrap;
  display: flex;
}

.edit-address-icon {
  //padding: 0 !important;
  display: flex;
}

.address-row {
  align-items: center;
}

.section {
  padding: 15px;
  background: ghostwhite;
}

.col-actions {
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.product-orders {
  @media only screen and (max-device-width: 640px) and (max-device-width: 768px) {
    width: 83%;
  }
  @media only screen and (min-device-width: 768px) {
    width: 95%;
  }
}

.google-map-height {
  @media only screen and (max-device-width: 640px) and (max-device-width: 768px) {
    height: 100vh;
  }
  @media only screen and (min-device-width: 768px) {
    //height: 100%;
  }
}

//.rtc-no-margin{
//  .rct-block {
//    width:fit-content;
//  }
//}
[class*='jss'] {
  svg {
    transform: scaleY(1) !important;
  }
}

.error {
  color: #e32726 !important;
  font-size: 0.75rem !important;
  font-weight: bold !important;
}

.order-customer-timetostart {
  font-size: 10px;
}

.trucks-drawer {
  background-color: #f7f7f6;

  .order-info-title {
    font-size: 12px;
    color: #a5a5a5;
  }

  .truck-id {
    font-size: 14px;
  }

  .info-title {
    font-size: 12px;
    color: #a5a5a5;
  }

  .trucks-divider {
    background-color: #f7f7f7;
    height: 10px;
  }
}

.order-action {
  .action-user {
    color: #43d0aa;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

table th {
  white-space: nowrap;
}

.white {
  span {
    color: #fff !important;
  }

  svg {
    fill: #fff !important;
  }
}

//.customRow {
//  display: flex;
//  flex-direction: row;
//  flex-wrap: nowrap;
//  align-items: center;
//  justify-content: center;
//  position: relative;
//  padding-right: 45px;
//  padding-left: 45px;
//
//  svg {
//    fill: #8c8c8c;
//    cursor: pointer !important;
//  }
//}

//.customRowOneIcon {
//  display: flex;
//  flex-direction: row;
//  flex-wrap: nowrap;
//  align-items: center;
//  justify-content: center;
//  position: relative;
//  padding-right: 55px;
//  padding-left: 55px;
//  svg {
//    fill: #8c8c8c;
//    cursor: pointer !important;
//  }
//}

.filtered-cell {
  background-color: $primary !important;
  color: #fff !important;

  svg {
    fill: #fff !important;
  }

  border: 1px solid $primaryHover !important;
}

.rotate0 {
  transform: rotate(0deg);
}

.rotate180 {
  transform: rotate(180deg);
}

//.single-filter-icon {
//  position: absolute;
//  right: 0;
//}
//
//.sort-icon {
//  position: absolute;
//  right: 15px;
//  margin-right: 6px;
//}

.clearFilter {
  float: right;
}

//.custom-table {
//  th > span {
//    color: #000;
//    font-size: 12.5px;
//    text-align: center;
//    font-weight: 600;
//    line-height: 1.5rem;
//    background-color: #F1F1F1;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//  }

//  th, td {
//    border: 1px solid #CFCFCF !important;
//    padding: 0 10px;
//  }
//
//  th {
//    height: 30px;
//    vertical-align: middle;
//  }
//
//  td {
//    height: 48px;
//    vertical-align: middle;
//    color: rgba(0, 0, 0, 0.87);
//    text-align: center;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//    font-weight: 300;
//  }
//}

//.custom-table-cursor {
//  th > span {
//    color: #000;
//    font-size: 12.5px;
//    text-align: center;
//    font-weight: 600;
//    line-height: 1.5rem;
//    background-color: #F1F1F1;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//  }
//
//  th, td {
//    border: 1px solid #CFCFCF !important;
//    padding: 0 10px;
//  }
//
//  th {
//    height: 30px;
//    vertical-align: middle;
//  }
//
//  td {
//    height: 48px;
//    vertical-align: middle;
//    color: rgba(0, 0, 0, 0.87);
//    text-align: center;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//    font-weight: 300;
//  }
//
//  td:hover {
//    cursor: grab !important;
//  }
//}

.black {
  color: #42444d;
}

.wrapping-table {
  th {
    white-space: normal !important;
  }
}

// .app-header {
// border-bottom: 1px solid #F1F1F1;
// }

.list-unstyled {
  padding: 0;
}

.suggestion-item {
  padding: 8px 16px;
  color: #000;
  cursor: pointer;
}

.background-color-icon {
  width: 100px;
  height: 100px;
  background-color: aliceblue;
}

.image-table {
  height: 90px;
  width: auto;
  margin: 10px;
}

.h-500px,
.ZoneMap {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
}

.map {
  margin-bottom: 100px !important;
}

.map > .ZoneMap {
  width: 100% !important;
  height: 100% !important;
}

.opening-hours-table {
  th {
    border: none !important;
    font-size: 14px !important;
  }
}

td:hover {
  .hover-cell {
    cursor: pointer;
    background: url('../../elkaso/expandIcon2.svg') no-repeat right top;
    background-size: 16px;
  }
}

td {
  .hover-cell {
    padding-right: 18px;
  }
}

tr {
  cursor: default !important;
}

.capitalize {
  text-transform: capitalize;
}

.border-bottom {
  border-bottom: 1px solid #f4f7fa !important;
}

.border-top {
  border-top: 1px solid #f4f7fa !important;
}

.padding-actions {
  margin: 0 !important;
  padding: 8px 4px;
}

.more {
  max-width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dialog-info-header {
  background-color: $primary !important;
  text-align: center;

  h2 {
    color: white !important;
  }
}

.dialog-order-header {
  background-color: #45d0ab !important;
  text-align: left;

  h2 {
    color: white !important;
  }
}

.select-dialog-layout {
  min-width: 250px;
  min-height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.edit-order-layout {
  min-width: 250px;
  min-height: 150px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.order-head {
  margin-top: 15px !important;

  tr th {
    font-size: 16px !important;
  }
}

.order-body {
  td {
    font-size: 14px !important;
  }

  tr:last-child {
    margin-top: 10px;
    border-top: 1px solid #ddd;
  }
}

.order-items {
  margin-top: 25px !important;
}

.total-order {
  font-weight: bold !important;
}

.map-dialog-content {
  margin-top: 120px;

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.map-dialog-address {
  height: 400px;
  margin-bottom: 50px;
}

.closeButton {
  position: absolute !important;
  right: 10px;
  top: 11px;
  left: inherit;
}

.delete-icon-dialog {
  position: absolute !important;
  right: 50px;
  top: 11px;
  left: inherit;
}

.message-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.min-width-150 {
  min-width: 150px !important;
}

.small-error {
  span {
    color: #e32726 !important;
    font-size: 0.75rem !important;
  }
}

.complete-order {
  span {
    font-weight: bold !important;
  }
}

.complete-order-price span {
  text-align: right !important;
}

.rbc-time-header {
  display: none !important;
}

.rbc-time-content {
  border: none;
}

.input-no-order {
  input,
  div::before {
    border: none !important;
  }

  input::after {
    border: none !important;
  }

  input,
  div {
    border: none !important;
  }
}

.time-slot-padding {
  padding-right: 15px;
  padding-left: initial;
}

.activation {
  margin-left: 20px;
  margin-top: -10px;
}

.transaction-dialog {
  margin-top: 20px !important;
}

.export-excel {
  float: right !important;
}

/*
.canvas-container {
  position: relative;
  height:60vh;
  width:80vw
}*/

.pointer {
  cursor: pointer;
}

.report-clear {
  margin-left: 15px !important;
}

.report-partnerInfo {
  span {
    font-size: 20px !important;
  }

  p {
    font-size: 18px !important;
  }
}

.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

/*.chartAreaWrapper {
  overflow-x: scroll;
}*/

.Select-input > input {
  display: flex;
  width: auto !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom-color: darkgrey;
  border-bottom-width: 1px;
}

.MuiFormControl-root .css-qcwa5y {
  display: none;
}

.date-history {
  margin-bottom: 10px !important;
}

.item-history {
  margin-bottom: 15px !important;
}

.user-info {
  color: $user-block-title;
}

.form-control {
  border-radius: 0 !important;
}

.cars-images {
  width: 13rem;
}

.cars-images-bg {
  width: 13rem;
  fill: $primary;
}

.secondary-text {
  color: $secondary;
}

a.link-table {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
}

a:hover.link-table {
  color: $primaryHover !important;
}

.map-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.width-600 {
  width: 600px;
}

.contactRoot {
  padding: 2px 4px;
  display: flex;
  align-items: center;

  .input {
    flex: 1;
  }

  .iconButton {
    padding: 10px;
  }
}

.reset-password {
  color: #a5a7b2;
  padding: 2rem 1.875rem;
  font-size: 1.3rem;
}

.access-denied {
  color: #a5a7b2;
}

.customShadow {
  .rct-block {
    box-shadow: none !important;
    border: 1px solid #80808080 !important;
    margin-bottom: -1px !important;
  }

  .rct-block-title {
    padding-left: 40px;
  }

  .rct-block-content {
    padding-top: 0 !important;
  }
}

.basicInfo {
  padding: 0 10%;
  .imgBg {
    width: 150px;
    height: 150px;
    background-color: #f2c94c;
    border-radius: 12px;

    p {
      color: white;
      font-size: 3rem;
    }
  }

  .MuiOutlinedInput-root-350 {
    position: relative;
    border-radius: 4px;
    padding: 5px 0;
  }
}

.signin {
  margin: 10px 0;
  height: 71px;

  > div > div {
    background-color: #fff;
  }

  input {
    padding: 15px;
    color: #000;
    font-size: 1rem;
    line-height: 1;
  }
}
//fire fox scroll
// :root{
//   scrollbar-color: #A5A7B2 #A5A7B2;
//   scrollbar-width: thin;
//   scrollbar-face-color: transparent;
//   scrollbar-track-color: transparent;
// }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.ql-editor {
  margin-left: 16px;
  margin-right: 16px;
  font-family: 'Helvetica Neue';
  color: #000;
  line-height: 1.5rem !important;
}

.ql-container.ql-snow {
  height: 300px;
}

.ql-size-small {
  font-size: 14px !important;
}

.ql-size-large {
  font-size: 16px !important;
}

.ql-size-huge {
  font-size: 20px !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: $primary !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $primary !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: $primary !important;
}

.pointer {
  cursor: pointer !important;
}

.custom-dropzone {
  height: 70px;
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  color: #8f8f8f;
  outline: none;
  transition: border 0.24s ease-in-out;
  border-radius: 12px;
  border: 2px dashed #999999;
  text-align: center;
  flex-grow: 1;
  p {
    padding: 1rem 0;
    margin-bottom: 0;
    cursor: pointer;
  }
  .cancelBtn {
    display: flex;
    padding: 1rem 2rem;
    cursor: pointer;
  }
}
.MuiBreadcrumbs-separator {
  margin-left: 5px !important;
  margin-right: 5px !important;
  margin-top: -5px !important;
  font-size: 2rem !important;
}
//.MuiBreadcrumbs-li{
//  a{
//  color: #4c4c4c !important;
//  font-weight: 500 !important;
//  }
//  //a:hover{
//  //  color: $primary !important;
//  //  text-decoration: none;
//  //}
//  //p:hover{
//  //  color: red !important;
//  //}
//}
.breadcrumbCheck {
  margin-top: 11px !important;
  margin-left: 8px !important ;
}
//tbody{
//  tr:hover{
//    box-shadow: 5px 5px 8px rgba(0,0,0,0.07) inset;
//  }
//  tr:nth-child(odd) {
//    background-color: rgba(0, 0, 0, 0.03);
//  }
//  tr:nth-child(even) {
//    background-color: $white;
//  }
//}

.notification-success:before {
  content: '' !important;
  background: url('../../icons/correct.svg') no-repeat;
  left: 5.6% !important;
  top: 53% !important;
}

.notification-error:before {
  content: '' !important;
  background: url('../../icons/error.svg') no-repeat;
  left: 5.6% !important;
  top: 53% !important;
}
.notification-success,
.notification-error {
  background: #1c1c1c !important;
  opacity: 0.9 !important;
}
.notification-container {
  width: 350px !important;
}
.notification .title {
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: flex-end !important;
  letter-spacing: 0.1px !important;
}
.notification .message {
  font-size: 12px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: flex-end !important;
  opacity: 0.77 !important;
  letter-spacing: 0.1px !important;
  margin-top: -3px !important;
}
.notification {
  border-radius: 12px !important;
  animation: fadeInDown 1000ms !important;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.custom-dropzone-active {
  color: $primary;
  border-color: $primary;
}
.hoverCell:hover {
  cursor: pointer;
  background: url('../../elkaso/expandIcon2.svg') no-repeat right top;
  background-size: 16px;
}
.minWidthDialog {
  min-width: 700px;
}
.message-list {
  background: url('../../img/chatsBG.png') repeat right top;
  margin: -30px;
  height: 80vh;
  direction: ltr !important;
  padding: 10px;
  border-radius: 10px;
}
.infoMessage + .rce-mbox {
  display: none;
}
.hide-next-message + .rce-mbox {
  display: none;
}
.rce-smsg {
  background: #ffffffab !important;
  color: grey;
}
.rce-mbox-text:after {
  content: '\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0';
}
.codePointer {
  cursor: pointer;
  color: #0075c9;
  &:hover {
    text-decoration: underline;
  }
}
.statisticsContainer {
  position: relative;
  width: 250px;
  height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #0c68bd;
  }
  .text {
    color: $primary;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    .icon {
      margin: 5px;
      font-size: 1.5rem;
    }
  }
  .helpIcon {
    position: absolute;
    cursor: help;
    top: 10px;
    right: 10px;
    color: rgba(0, 0, 0, 0.3);
    &:hover {
      color: $primary;
    }
  }
  .date {
    font-size: 2rem;
    font-weight: bold;
    color: #0c68bd;
  }
}
.res-statisticsContainer {
  position: relative;
  width: 150px;
  height: 100px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .number {
    font-size: 1rem;
    font-weight: bold;
    color: #0c68bd;
  }
  .text {
    color: $primary;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    .icon {
      margin: 5px;
      font-size: 1.25rem;
    }
  }
  .helpIcon {
    position: absolute;
    cursor: help;
    top: 10px;
    right: 10px;
    color: rgba(0, 0, 0, 0.3);
    &:hover {
      color: $primary;
    }
  }
  .date {
    font-size: 1rem;
    font-weight: bold;
    color: #0c68bd;
  }
}
.invitation-padding {
  padding-right: 15px !important;
}
//chats list
.support-chats-layout {
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid $cardBorderColor;
  margin: 10px;
  //Extra small devices
  @media only screen and (max-width: 600px) {
    max-width: 350px !important;
    min-width: 350px !important;
  }
  @media only screen and (min-width: 600px) {
    max-width: 300px !important;
    min-width: 300px !important;
  }
  @media only screen and (min-width: 768px) {
    max-width: 340px !important;
    min-width: 340px !important;
  }
  @media only screen and (min-width: 992px) {
    max-width: 430px !important;
    min-width: 430px !important;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 585px !important;
    min-width: 585px !important;
  }
  @media (min-width: 1200px) and (max-width: 1600px) {
    max-width: 420px !important;
    min-width: 420px !important;
  }
}
.support-chats-list {
  overflow: hidden !important;
  padding-top: 5px;
  padding-bottom: 10px;
}
.support-chats-list-loading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.rce-container-citem {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.rce-citem:hover {
  border-radius: 12px;
}
.rce-citem-support .rce-citem {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.custom-indicator .rce-citem .rce-citem-body .rce-citem-body--bottom .rce-citem-body--bottom-tools-item-hidden-hover,
.custom-indicator .rce-citem .rce-citem-body .rce-citem-body--bottom .rce-citem-body--bottom-tools {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: $primary;
  opacity: 1 !important;
}
.restaurant-img .rce-citem .rce-citem-avatar .rce-citem-status {
  background: url('../../img/restaurant.png') no-repeat !important;
  width: 25px;
  height: 25px;
}
.supplier-img .rce-citem .rce-citem-avatar .rce-citem-status {
  background: url('../../img/supplier.png') no-repeat !important;
  width: 25px;
  height: 25px;
}
.rce-citem-support .rce-citem,
.rce-citem-support .rce-citem .rce-citem-body .rce-citem-body--bottom-title,
.rce-citem-support .rce-citem .rce-citem-body .rce-citem-body--top-time {
  background-color: $primary !important;
  border-radius: 12px !important;
  color: #ffffff !important;
}
//chat form
.support-chat-form {
  border: 2px solid $cardBorderColor;
  border-radius: 12px;
}
.message-list-support {
  height: 60.5vh;
  background: url('../../img/chatsBG.png') repeat right top;
}
.rce-mbox-time {
  position: unset !important;
}
.chat-navBar {
  margin-left: 10px !important;
  font-size: 17px;
  margin-top: 13px;
}
.chat-navBar:hover {
  text-decoration: underline;
  cursor: pointer;
  color: $primary;
}
.rce-navbar.light {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #ffffff !important;
}
.support-chat-input:before,
.support-chat-input:after {
  display: none !important;
}
.support-chat-sendButton {
  border: 2px solid $cardBorderColor !important;
  margin: 3px !important;
}
.support-chat-stickySearch {
  position: sticky;
  top: 0;
  background: #ffffff;
  border-radius: 12px;
  z-index: 999;
}
//chat form messages
.rce-mbox {
  background-color: antiquewhite !important;
}
.rce-mbox-right-notch {
  fill: aliceblue !important;
}
.rce-mbox-left-notch {
  fill: antiquewhite !important;
}
.rce-mbox-right {
  background-color: aliceblue !important;
}

//chat loader
.support-noInternet-loader {
  margin-right: 5px !important;
}
.support-chatRoom-status {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.rce-mlist-down-button {
  color: $primary !important;
}

//body of chat message
.rce-mbox-body {
  max-width: 300px;
}

//seen issue
.rce-container-mbox:last-child {
  padding-bottom: 30px;
  position: relative !important;
}
.support-chat-seen {
  text-align: right;
  padding: 5px 25px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.avatar-general {
  width: 60px !important;
  height: 60px !important;
  background-color: #a7a7a7 !important;
}
.image-cropper-icon:hover {
  background-color: #d2d3d775 !important;
}
.image-cropper-icon {
  position: absolute !important;
  top: 15px;
  right: 0;
}
.cropper-view-box {
  border-radius: 50%;
}
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
.filters-layout {
  background-color: #ffffff;
  border-radius: 12px;
  border: 2px solid #e6e7ef;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.border-none {
  border: none !important;
}
.merged-restaurants {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.custom-error-component-layout {
  background-color: rgb(255, 245, 244);
  padding: 20px;
  height: 100px;
  border: 1px solid #f9d3cf;
  border-radius: 8px;
}
.custom-error-just-details {
  display: flex;
  align-items: center;
}
.merge-button {
  justify-content: flex-end;
  display: flex;
}
.edit-admin-info {
  display: flex;
  align-items: center;
}
.support-chat-form-height {
  max-height: 70vh;
}
.support-chats-scroll::-webkit-scrollbar {
  display: none;
}
.primary-color {
  color: $primary;
}
.darkGrey {
  color: #5e5e5e;
}
.font-bold {
  font-weight: bold;
}
.order-details-grey-color {
  color: rgba(66, 68, 77, 0.65);
}
a:hover.order-details {
  color: $primary !important;
}
.order-items {
  th:first-child {
    font-weight: 500 !important;
  }
}
.order-items .item-header {
  span {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
    font-weight: 500;
  }
}
.order-items .item-body {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
}
.restaurant-link {
  color: $primary;
  &:hover {
    text-decoration: underline;
  }
}
.restaurant-button {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  color: #5e5e5e !important;
  background-color: rgba(226, 226, 226, 0.4);
  box-shadow: none !important;
}
h2 {
  margin-bottom: 0 !important;
}
.res-details-list:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.res-details-list:nth-of-type(even) {
  background-color: $white;
  border-radius: 10px;
}
.list-scroll::-webkit-scrollbar {
  display: none;
}
.restaurant-list-empty {
  height: 74vh;
}
.res-profile-margin {
  margin: 1.5rem 0 !important;
}
.init-loading {
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  &::after {
    overflow: hidden;
    -webkit-animation: ellipsis 1s infinite;
    animation: ellipsis 1s infinite;
    content: '\2026';
    font-size: 2em;
    opacity: 0;
  }
}
@-webkit-keyframes ellipsis {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ellipsis {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.custom-border {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.direction-ltr {
  direction: ltr !important;
}
.invoice-image {
  width: 50%;
  height: 200px;
  cursor: zoom-in;
}
.custom-filter-select .MuiInputBase-input {
  margin-bottom: 10px;
}
.country-flag {
  width: 100%;
  border: 1px solid #ccc;
}
