.rct-page {
  background-color: #fafaf9;
}
.rct-page-wrapper {
  [direction='right'] {
    border-right: none;
    z-index: 999 !important;
  }
}
.rct-sidebar {
  background-repeat: no-repeat;
  background-size: cover;
  width: $sidebar-width;
  background-position: top center;
  overflow: hidden;
  transition: all 200ms ease 0s;
  height: 100%;
  .rct-sidebar-content {
    position: relative;
    z-index: 0;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: -1;
      opacity: 0.9;
    }
    &.sidebar-overlay-dark:before {
      background: $white;
      color: $grey;
    }
    &.sidebar-overlay-light:before {
      background-color: $overlay-light;
    }
  }
  &.background-none .rct-sidebar-content:before {
    opacity: 1 !important;
  }
  .site-logo {
    align-items: center;
    display: flex;
  }
  .rct-sidebar-wrap {
    background: $sidebar-color !important;
    padding: 0 !important;
    .rct-scroll {
      > div > div {
        overflow: hidden !important;
        > div {
          display: none !important;
        }
      }
    }
    @media (max-width: 1199px) {
      .rct-scroll {
        > div > div {
          overflow: hidden !important;
          > div {
            display: none !important;
          }
        }
      }
    }
    .top-sidebar {
      .sidebar-user-block {
        padding: 1.25rem;
        .rct-dropdown {
          cursor: pointer;
          .dropdown-menu {
            background-color: $base-light;
            width: 200px;
            ul {
              li:not(.user-profile-top) {
                box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
                margin: 0.3125rem;
                a {
                  background: $sidebar-color;
                  padding: 0.75rem 1rem;
                  display: block;
                  // svg {
                  //    font-size: 1rem;
                  // }
                  span:not(.badge) {
                    color: $body-color;
                    font-size: $font-size-sm;
                    transition: color 0.3s ease;
                  }
                  &:hover {
                    span:not(.badge) {
                      color: $primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .rct-sidebar-nav {
    .rct-mainMenu {
      padding-top: 30px !important;
      //padding-top: 45px !important;
      li {
        &.side-title {
          font-size: 12px !important;
          line-height: 15px !important;
          position: static;
          padding: 0.5rem 1.5rem !important;
          letter-spacing: 0.01em;
          color: $base-light !important;
        }
        &.list-item {
          position: relative;
          .menu-icon {
            font-size: 14px !important;
          }
          &:after {
            position: absolute;
            content: '\f2fb';
            font-family: Material-Design-Iconic-Font;
            right: 25px !important;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            transform: rotate(0);
            transition: all 0.2s ease-in-out;
          }
          &.item-active {
            &:after {
              transform: rotate(90deg);
            }
            span.menu {
              font-weight: $fw-semi-bold;
            }
          }
        }
        a {
          .menu-icon {
            svg {
              margin: 0 15px 0 15px;
            }
            img {
              margin: 0 15px 0 15px;
            }
          }
        }
      }
      > li:not(.side-title) {
        padding: 1rem 1.5rem;
      }
      > li:not(.list-item) {
        padding: 0 15px 5px 15px;
        display: block;
        a {
          display: flex;
          align-items: center;
          padding: 10px 0 10px 0;
          //padding: 15px 0 15px 0;
        }
      }
      > li:not(.list-item):hover {
        background-color: transparent !important;
      }
      .sub-menu {
        ul {
          li {
            padding: 0;
            display: block;
            a {
              display: block;
              padding: 0.75rem 1.5rem 0.75rem 3.4rem;
              &.item-active {
                font-weight: $fw-semi-bold;
              }
            }
            &.list-item {
              padding: 0.75rem 1.5rem 0.75rem 3.4rem;
            }
          }
        }
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    transform: translate3d(0px, 50px, 0px);
  }
}
.rct-sidebar {
  .sidebar-overlay-dark,
  .sidebar-overlay-light {
    a:not(.item-active),
    li:not(.item-active) {
      color: $not-active-item-font !important;
      svg {
        fill: $not-active-item-font !important;
      }
      &:hover {
        background-color: #f8f8fc;
        border-radius: 12px !important;
      }
    }
    a.item-active,
    li.item-active {
      border-radius: 12px !important;
      background: $item-active;
      color: #ffffff;
      svg {
        color: #ffffff !important;
        fill: $active-item-font !important;
      }
    }
  }
}

.collapsed-sidebar {
  .rct-header {
    left: 0;
  }
  .rct-sidebar {
    width: 0;
  }
  .rct-app-content {
    width: 100%;
  }
}

/*========= Customizer ==========*/

.rct-customizer {
  width: $sidebar-width;
  overflow-x: hidden;
  header {
    background: $gray-900;
    color: $white;
  }
  .chat-area {
    .chat-head {
      @include border(1px solid, $border-color, top);
    }
    .chat-head,
    .chat-body {
      padding: 0.625rem;
    }
    .chat-body {
      .media-body {
        padding: 0.625rem !important;
        margin: 3px;
      }
    }
    .attachment {
      align-items: center;
      display: flex;
    }
    h3 {
      font-size: 14px;
      margin: 0;
    }
    .send-icons {
      ul {
        > li {
          &:nth-last-of-type(-n + 2) {
            display: none;
          }
        }
      }
    }
    .chat-footer {
      padding: 0;
    }
  }
  .chat-sidebar {
    .badge-xs {
      padding: 5px;
    }
    .media-body {
      h5 {
        font-size: 14px;
      }
    }
  }
  .panel-title {
    font-size: 14px;
    font-weight: bold;
    margin: 10px;
  }
}
.sidebar-logo {
  display: flex !important;
  border-bottom: 2px solid rgb(230, 231, 239) !important;
  min-height: 67px !important;
  align-items: flex-start !important;
  padding: 15px 15px 0 15px !important;
  img {
    max-width: 40px !important;
    margin-right: 4px !important;
  }
  h2 {
    padding-top: 11px !important;
  }
}
.list-unstyled {
  padding: 0;
}
.app-main-container {
  display: flex;
  flex-wrap: nowrap;
  > div {
    > div {
      border-right: 2px solid $cardBorderColor;
    }
  }
}
.sidebar-image {
  width: 1.2rem;
  height: 1.2rem;
}
//RTL
body.rtl {
  .preload-direction {
    transform: scaleX(-1) !important;
  }
  .sidebar-logo {
    direction: rtl !important;
  }
  img {
    margin-right: 0 !important;
    margin-left: 4px !important;
  }
}
