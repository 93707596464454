//
// Basic Bootstrap table
//

.table {
  width: 100%;
  max-width: 100%;
  //margin-bottom: $spacer;
  //margin-top: 7px;
  // background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    vertical-align: top;
  }

  thead th {
    vertical-align: bottom;
    background-color: #ffffff;
  }
}

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    th {
      color: $table-dark-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
    }
  }
}

.table-dark {
  color: $table-dark-color;

  &.table-bordered {
    border: 0;
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}
.actions {
  padding: 0 !important;
  min-width: 150px !important;
}

td {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  text-align: left;
  height: 45px;
}

button:hover {
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
}
