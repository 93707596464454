body.rtl {
  .submit-position {
    float: left !important;
  }
}
.confirmation-dialog {
  max-width: 350px !important;
}
.header-dialog {
  h2 {
    font-weight: bold !important;
    color: #2c2d33 !important;
    font-size: 19px !important;
  }
}
.dialog-actions {
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 20px !important;
  button {
    width: 100% !important;
  }
}
.dialog-actions .button-dialog-text {
  margin: 10px 0 !important;
  font-weight: bold;
}
.info-dialog {
  text-align: right !important;
}
