.display-status {
  background-color: red;
}

.slider {
  max-width: 50% !important;
}
.slider-control-centerright button,
.slider-control-centerleft button {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  width: 60px !important;
  height: 50px !important;
}
.slider-control-centerright button:hover,
.slider-control-centerleft button:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 5px 15px rgba(218, 210, 223, 0.4) !important;
}
.delete-slide {
  max-height: 100% !important;
  width: 100% !important;
  display: block;
}
.slider-slide > img {
  max-height: 100% !important;
  width: 100% !important;
  display: block;
}

.slider-control-bottomcenter {
  display: none !important;
}
.multi-images:hover {
  cursor: pointer !important;
}
button:hover {
  box-shadow: 0 5px 15px rgba(218, 210, 223, 0.4) !important;
}
.custom-img {
  width: 100% !important;
}
.chip-styles {
  height: 15px !important;
  margin: 2px !important;
  padding: 10px 0 10px 0 !important;
}
.activation-styles {
  /*margin-left: 0!important;
  margin-right: 0 !important;*/
  margin: 0 !important;
  > span {
    margin-right: 5px !important;
    font-size: 12px !important;
  }
}
.make-done {
  svg {
    fill: $success !important;
  }
  span {
    color: $success !important;
    margin-left: 5px;
    font-size: 12px;
  }
}
.make-done-comp {
  margin-left: 20px !important;
}
.make-done-styles {
  margin-left: 0 !important;
  margin-right: 0 !important;
  > span {
    font-size: 12px !important;
  }
}
.true-icon {
  color: $green !important;
  vertical-align: middle !important;
  svg {
    font-size: 20px !important;
  }
}
.false-icon {
  color: #f22235 !important;
  vertical-align: middle !important;
  svg {
    font-size: 20px !important;
  }
}
.custom-badge {
  font-size: 100%;
  //padding: .5rem 2rem !important;
}
