/*======== App Global (Main Structure) Mention Here =======*/
#root,
.app {
  height: 100%;
  width: 100%;
}

.app-main-container {
  display: flex;
  flex-wrap: nowrap;
}

.rct-footer {
  box-shadow: $footer-box-shadow;
  background-color: $footer-bg;
  padding: 1.25rem; //20px;
  z-index: 9;
  h5 {
    margin-bottom: 0;
    color: $gray-600;
    font-size: 0.875rem; //14px;
  }
}

.rct-block {
  background-color: $block-bg;
  position: relative;
  border: 2px solid $cardBorderColor;
  border-radius: 10px !important;
}
.rct-block-title {
  padding: 1.5rem 3.5rem 1.5rem 3.5rem;
  position: relative;
  .badge {
    vertical-align: middle;
  }
  h4 {
    margin-bottom: 0;
    color: $block-title-color;
    font-size: $block-title-font-size;
    text-transform: $block-title-transform;
    font-weight: $block-title-font-weight;
    line-height: 30px;
  }
  .contextual-link {
    position: absolute;
    top: 20px;
    right: 20px;
    a {
      color: $block-contextual-color;
      font-size: $block-contextual-font-size;
      margin-right: 15px;
      &:hover {
        color: $primaryHover;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.rct-block-footer {
  background-color: $block-bg;
  padding: $block-title-spacing;
  border-top: $block-footer-border-width solid $block-footer-border-color;
}

.card-footer {
  background-color: $block-bg;
}

.sub-title {
  padding: $block-title-spacing;
  margin-bottom: 1.25rem; //20px;
  h4 {
    margin-bottom: 0;
    color: $block-title-color;
    font-size: $block-title-font-size;
    text-transform: $block-title-transform;
    font-weight: $block-title-font-weight;
  }
}

[class*='gradient-'],
[class^='gradient-'] {
  h4 {
    color: $white;
    border-color: $white;
  }
}

/*========== Page Breadcrumb ============*/
.page-title {
  .page-title-wrap > i {
    color: $breadcrumb-font-color;
    font-size: 1.875rem;
  }
  h2 {
    color: $primary;
    display: inline-block;
    font-size: 1.875rem;
    margin: 0;
  }
  i {
    margin: 0 5px;
  }
}

.breadcrumbTitle {
  p {
    margin: 0;
    font-family: 'Heebo', sans-serif !important;
    font-size: 1.25rem;
    color: #262626;
  }
}
.breadcrumbTitle .secondItem {
  font-family: 'Heebo', sans-serif !important;
  font-size: 1.25rem;
  font-weight: 500 !important;
  color: #6d6f7d;
}
.breadcrumbTitle,
.secondItem:hover {
  color: $primary;
}
.breadcrumbTitle .firstItem {
  font-weight: 500 !important;
}

/*========== Custom CSS ============**/
tr[class^='MuiTableRow'] {
  cursor: pointer;
}

.SingleDatePicker {
  width: 100%;
  .SingleDatePickerInput {
    width: 100%;
    .DateInput {
      width: 100%;
    }
  }
}

.time_picker_container {
  width: 20vw !important;
  .picker_container {
    margin: 0 !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-bottom: 40px !important;
  }
}

.custom-error {
  background-color: #ffe7e7 !important;
}
.img-icon-preview {
  max-height: 64px;
  max-width: 64px;
  background: lightgray;
  display: block;
}

.new-notification,
.notification-dropdown .dropdown-menu .dropdown-list li.new-notification:hover {
  background-color: #edf2fa !important;
}

.alert-lunch-btn {
  font-weight: 700 !important;
}
.map {
  height: 550px;
  > div {
    width: 97% !important;
    height: 60% !important;
  }
}

.dialog-info-header {
  background-color: $primary !important;
}

.custom-dropzone-active {
  color: $primary;
  border-color: $primary;
}
.suggestion-item--active {
  background-color: $primary;
  color: #fff;
}
.customCellHover,
.customCellHover:hover {
  background-color: rgba($color: $primary, $alpha: 0.3) !important;
}

.center-center {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  // height: 100%;
}

body {
  text-align: left;
}
.align {
  text-align: left;
}

@media (min-width: 1200px) {
  .login {
    overflow: hidden !important;
    height: 100% !important;
  }
  .login-content {
    transform: scale(0.9) !important;
  }
  .login-logo-layout {
    height: 100% !important;
  }
  .back-button {
    //transform: scale(0.8) !important;
    margin-left: 120px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .login {
    overflow: hidden !important;
    height: 100% !important;
  }
  .login-content {
    transform: scale(0.5) !important;
  }
  .login-logo-layout {
    height: 100% !important;
    img {
      transform: scale(0.6) !important;
    }
  }

  .back-button {
    margin-left: 75px !important;
  }
}

//Medium screens
@media (min-width: 768px) and (max-width: 991px) {
  .login-logo-layout {
    height: 100% !important;
    img {
      transform: scale(0.5) !important;
    }
  }
  .login {
    height: 100% !important;
  }

  .login-content {
    transform: scale(0.5) !important;
  }
  .back-button {
    margin-left: 40px !important;
    button {
      span {
        font-size: 16px !important;
        > span svg {
          font-size: 20px !important;
        }
      }
    }
  }
}
//Mobile screens
@media (max-width: 767px) {
  .login-logo-layout {
    height: 50% !important;
    img {
      min-width: auto !important;
      width: auto !important;
      transform: scale(0.6) !important;
      position: absolute !important;
      top: auto !important;
    }
  }
  .login {
    overflow: hidden !important;
    height: 50% !important;
  }
  .login-content {
    transform: scale(0.6) !important;
  }
  .signin {
    margin-bottom: 2em !important;
    width: auto !important;
  }
  .login-title {
    font-size: 20px !important;
  }
  .login-info {
    font-size: 12px !important;
    max-width: 300px !important;
  }
  .login-button {
    span {
      font-size: 14px;
    }
  }
  .back-button {
    display: none !important;
  }
}
.login {
  background: $layout-color;
  height: 100%;
}
.login-content {
  display: block;
  position: relative;
}
.login-logo-layout {
  height: 100%;
  background: $sidebar-color;
  img {
    transform: scale(0.7);
  }
}
.login-container {
  left: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.login-title {
  font-size: 28px;
  font-family: 'Helvetica Neue', sans-serif;
  color: rgb(62, 60, 65);
  line-height: 22px;
  margin-bottom: 20px;
  font-weight: bold !important;
}
.login-info {
  font-size: 16px;
  font-family: 'Helvetica Neue', sans-serif;
  color: #919191;
  line-height: 22px;
  margin-bottom: 27px;
  max-width: 450px;
}
.login-button {
  min-width: 215px !important;
  height: 48px !important;
}
.forget-password {
  margin-top: 23px;
  margin-bottom: 23px;
}
.forget-password-button {
  min-width: 215px !important;
  height: 48px !important;
  margin-top: 23px !important;
}
.login-back-button {
  min-width: 280px !important;
  height: 48px !important;
}
.signin {
  //margin-bottom: 18px;
  margin-bottom: 2em;
  height: 42px;
  width: 500px;
  > div > div {
    background-color: #fff;
  }
  > div > div > div {
    background-color: #fff !important;
  }
  > div > div:before {
    content: none !important;
  }
  > div > div > div:before {
    content: none !important;
  }
  //> div > div:after {
  //  content: none !important;
  //}
  input {
    padding: 15px;
    color: #231f20 !important;
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
/*.flex-center-center{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex-center-left{
  display: flex !important;
  flex-direction: column !important;
  justify-content: left !important;
}*/
.back-button {
  margin-top: 50px;
  //margin-left: 120px;
  position: absolute;
  top: 0;
  left: 0;
  button {
    span {
      font-size: 20px;
      font-weight: bold;
      > span svg {
        vertical-align: middle;
        font-size: 35px;
      }
    }
  }
  button:hover {
    background: none !important;
    box-shadow: none !important;
  }
}
.expired-session {
  justify-content: center !important;
  img {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .expired-session {
    transform: scale(0.9) !important;
    button {
      margin-top: -20px !important;
    }
  }
}
.class-404-background {
  background: linear-gradient(155.21deg, rgba(13, 105, 169, 0) 0.51%, rgba(1, 70, 118, 0.09) 100%);
  //height: 100%;
  height: calc(100vh);
}
.font-12 {
  font-size: 12px !important;
  font-family: 'Helvetica Neue', sans-serif;
}
.font-16 {
  font-size: 16px !important;
  font-family: 'Helvetica Neue', sans-serif;
}
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.font-size-h1 {
  font-size: 5rem;
}
.font-size-h3 {
  font-size: 3rem;
}

.ar-quill .ql-editor {
  direction: rtl;
}
.login {
  // background: linear-gradient(155.21deg, rgba(13, 105, 169, 0) 0.51%, rgba(1, 70, 118, 0.09) 100%);
  background: linear-gradient(167.96deg, rgba(13, 105, 169, 0) 0.51%, rgba(1, 70, 118, 0.09) 100%);
  height: 100%;
}
.flex-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.width-70 {
  width: 60%;
}
.height-12 {
  height: 12px;
}

.un-selected-image {
  margin: 30px;
  border: 2px rgba(128, 128, 128, 1) solid;
  border-radius: 50%;
  padding: 15px;
  font-weight: 600;
  cursor: pointer;
  color: rgba(128, 128, 128, 1);
}
.selected-image {
  margin: 30px;
  border: 2px $primary solid;
  border-radius: 50%;
  padding: 15px;
  background-color: $primary;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.color-yellow {
  background-color: #f2c94c !important;
  color: #f2c94c !important;
}
.color-dark-green {
  background-color: #e86d1e !important;
  color: #e86d1e !important;
}
.color-light-green {
  background-color: #17b96a !important;
  color: #17b96a !important;
}
.color-orange {
  background-color: #e53e51 !important;
  color: #e53e51 !important;
}
.color-blue {
  background-color: #b88c9e !important;
  color: #b88c9e !important;
}
.secondary-orange {
  [aria-label='COLOR-7'] {
    border: solid $primaryHover 2px !important;
  }
}
.primary-orange {
  [aria-label='COLOR-2'] {
    border: solid $primaryHover 2px !important;
  }
}
.flex-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.no-border-select {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  div::before,
  div::after {
    display: none;
    content: '';
  }

  div {
    text-overflow: initial !important;
    padding: 0 !important;
    width: 22px;
    min-width: 20px !important;
    &:focus {
      background-color: transparent !important;
    }
  }
  svg.MuiSelect-icon {
    display: none;
  }
}

.no-border-select:hover {
  background-color: rgba(0, 0, 0, 0.04);
  // box-shadow: 5px 5px 10px 0px rgba(145, 92, 182, 0.4), -5px -5px 10px 3px #ffffff;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
//th,td{
//  padding: 0 5px !important;
//}

.animation-target {
  -webkit-animation: animation 3000ms linear both;
  animation: animation 3000ms linear both;
}

.center-col,
.center-col a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.center-row {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-menu {
  ul {
    padding: 0;
  }
}
.bg-black {
  background-color: #000;
}
//.rct-mainMenu{
//  a:hover{
//    background-color: #111;
//  }
//  a.item-active{
//    border-left: 5px solid $primary;
//    border-right: none;
//  }
//}
.rct-page-content {
  padding: 30px;
  padding-bottom: 100px !important;
  //padding: 40px 40px 45px 40px;
}
.seprator {
  border: 0.5px solid #ccc;
  height: 25px;
  margin: 12px;
}
.padding-30 {
  padding: 0 30px;
}
.floatingBtns {
  position: sticky;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  float: right;
}
.searchItem {
  width: 100%;
  display: flex;
  align-items: center;
}
.MuiListItem-container {
  width: 100%;
}
.MuiAutocomplete-inputFocused {
  width: 100%;
}
.listItemHover:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.main {
  background-color: rgba($color: $primary, $alpha: 0.3);
}
.MuiStepLabel-label.MuiStepLabel-completed {
  color: $primary !important;
}
.MuiStepLabel-label.MuiStepLabel-active {
  color: $primary !important;
}
.invitationBtn {
  //width: 50%;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  margin: 5px 0;
  color: #fff;
  font-weight: bolder;
  background-color: $primary;
  cursor: pointer;
  &:hover {
    box-shadow: 3px 4px 10px 2px rgba(0, 0, 0, 0.2);
  }
  &:focus {
    box-shadow: inset 3px 4px 10px 2px rgba(0, 0, 0, 0.2);
  }
}
.reject {
  border: 2px solid $primary;
  color: $primary;
  background-color: transparent;
}
.customLabel > .MuiFormControlLabel-label {
  color: $primary;
  font-size: 1.3rem;
  font-weight: bolder;
}
.MuiRadio-colorPrimary.Mui-checked {
  [class*='jss'] svg {
    transform: none !important;
  }
}

/* Customize the label (the container) */
.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $primary;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #777;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .checkmark {
  background-color: rgba($color: $primary, $alpha: 0.3);
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
  background-color: $primary;
  border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.img-bg-preview {
  max-height: 30rem;
  width: auto;
  display: block;
}
