.middleRow {
  align-self: center;
}
.fontBold {
  font-weight: bold;
}
.font-24 {
  font-size: 24px;
}
.pointer {
  cursor: pointer;
}
.flex-item-center {
  display: flex;
  align-items: center;
}
.width-50 {
  width: 50px !important;
}
.width-25 {
  width: 25px !important;
}
.width-100 {
  width: 100px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.width-300 {
  width: 300px !important;
}
.min-width-200 {
  min-width: 200px !important;
}
.min-width-300 {
  min-width: 300px !important;
}
.min-width-400 {
  min-width: 400px !important;
}
.width-400 {
  width: 400px !important;
}
.width-500 {
  width: 500px !important;
}
.width-700 {
  min-width: 700px !important;
}
.marginTop-10 {
  margin-top: 10px !important;
}
.no-bgColor-hover {
  &:hover {
    background-color: unset !important;
  }
}
.break-word {
  word-break: break-word !important;
}
.border-layout {
  border: 2px solid $cardBorderColor !important;
  border-radius: 10px !important;
}
.center-center {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  // height: 100%;
}
.start-center {
  justify-content: center !important;
  display: flex;
  flex-direction: row;
  align-items: start !important;
  height: 100%;
}
.flex-center-center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex-center-left {
  display: flex !important;
  flex-direction: column !important;
  justify-content: left !important;
}
.flex-center-right {
  display: flex !important;
  flex-direction: column !important;
  justify-content: right !important;
}
.capitalize {
  text-transform: capitalize;
}
.radius-50 {
  border-radius: 50px;
}
.radius-circle {
  border-radius: 50%;
}
.z-index {
  z-index: 999 !important;
}
.padding-12 {
  padding: 12px !important;
}
.padding-right-100 {
  padding-right: 100px !important;
}
.padding-left-100 {
  padding-left: 100px !important;
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.text-align-center {
  text-align: center !important;
}
